import { LoadingButton } from '@mui/lab';
import { CustomizedDialog } from 'components/common';
import AdminUserForm from './AdminUserForm';
import { CreateAdminUserDto, createAdminUserSchema } from 'dto/admin-users';
import { useCreateAdminUser } from 'hooks/admin-users';
import { locales } from 'utils/constants';

type PropsType = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

function CreateAdminUserDialog(props: PropsType) {
  const { mutate, isLoading } = useCreateAdminUser();

  const initialValues = createAdminUserSchema.cast({}) as CreateAdminUserDto;

  function onSubmit(values: CreateAdminUserDto): void {
    mutate(values, {
      onSuccess: () => {
        props.onSuccess();
      },
    });
  }

  return (
    <CustomizedDialog
      open={props.open}
      onClose={props.onClose}
      title={locales.create}
      footer={[
        <LoadingButton
          key="cancel"
          color="error"
          variant="outlined"
          loading={isLoading}
          onClick={props.onClose}
        >
          {locales.cancel}
        </LoadingButton>,
        <LoadingButton
          key="submit"
          color="primary"
          variant="contained"
          type="submit"
          form="AdminUserForm"
          loading={isLoading}
        >
          {locales.submit}
        </LoadingButton>,
      ]}
    >
      <AdminUserForm<CreateAdminUserDto>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={createAdminUserSchema}
      />
    </CustomizedDialog>
  );
}

export default CreateAdminUserDialog;
