import { personAPI } from 'api/person';
import { PersonDto } from 'dto/person';
import { useQuery } from 'react-query';
import { QUERY_KEY } from 'utils/enums';

type PropsType = {
  id: number;
  enabled?: boolean;
  onSuccess?: (data: PersonDto) => void;
};

export function useFindOnePerson(props: PropsType) {
  const query = useQuery({
    queryKey: [QUERY_KEY.FIND_ONE_PERSON, props.id],
    queryFn: () => personAPI.findOne(props.id),
    enabled: !!props.id || props.enabled,
    onSuccess: props.onSuccess,
  });

  return query;
}
