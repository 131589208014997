import { adminUsersAPI } from 'api/admin-users';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { locales } from 'utils/constants';
import { QUERY_KEY } from 'utils/enums';

export function useUpdateMyProfile() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: adminUsersAPI.updateMyProfile,
    onSuccess: (data) => {
      queryClient.setQueryData(QUERY_KEY.GET_MY_PROFILE, data);
      toast(locales.updateSuccess, { type: 'success' });
    },
  });

  return mutation;
}
