import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SxProps,
  Theme,
} from '@mui/material';
import { CONTROL_TYPE } from 'utils/enums';

const itemHeight = 48;
const itemPadding = 8;

type PropsType = {
  name?: string;
  label?: React.ReactNode;
  margin?: 'dense' | 'normal' | 'none';
  fullWidth?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  value?: CONTROL_TYPE[];
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  onChange?: (e: React.ChangeEvent<any>) => void;
  allowClear?: boolean;
  sx?: SxProps<Theme>;
};

function SelectControlType(props: PropsType) {
  return (
    <FormControl margin={props.margin} fullWidth={props.fullWidth}>
      <InputLabel id="SelectControlTypeLabel">{props.label}</InputLabel>
      <Select
        labelId="SelectControlTypeLabel"
        input={
          <OutlinedInput
            label={props.label}
            name={props.name}
            onBlur={props.onBlur}
            onChange={props.onChange}
          />
        }
        value={props.value ?? ''}
        style={{ padding: 0 }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: itemHeight * 5 + itemPadding,
            },
          },
          disableAutoFocusItem: true,
        }}
        sx={props.sx}
      >
        {Object.values(CONTROL_TYPE).map((controlType) => (
          <MenuItem key={controlType} value={controlType}>
            <ListItemText primary={controlType} />
          </MenuItem>
        ))}
      </Select>
      {props.error && <FormHelperText error>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}

export default SelectControlType;
