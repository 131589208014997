import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import {
  ADMIN_USER_STATUS,
  adminUserStatusOptions,
  getAdminUserStatusLabel,
  getAdminUserStatusLabels,
} from 'utils/enums';

const itemHeight = 48;
const itemPadding = 8;

type PropsType = {
  name?: string;
  label?: React.ReactNode;
  margin?: 'dense' | 'normal' | 'none';
  fullWidth?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  value?: ADMIN_USER_STATUS[] | ADMIN_USER_STATUS;
  onBlur?: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  onChange?: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  multiple?: boolean;
  size?: 'small' | 'medium';
  width?: number;
};

function SelectAdminUserStatus(props: PropsType) {
  let value = props.value;
  if (props.multiple && !value) {
    value = [];
  }

  return (
    <FormControl
      margin={props.margin}
      fullWidth={props.fullWidth}
      sx={{ width: props.width }}
    >
      <InputLabel
        id="SelectAdminUserStatusLabel"
        size={props.size === 'medium' ? 'normal' : props.size}
      >
        {props.label}
      </InputLabel>
      <Select
        id="SelectAdminUserStatus"
        labelId="SelectAdminUserStatusLabel"
        multiple={props.multiple}
        input={
          <OutlinedInput
            label={props.label}
            name={props.name}
            onBlur={props.onBlur}
            onChange={props.onChange}
          />
        }
        value={value ?? ''}
        renderValue={(selected) =>
          typeof selected === 'object'
            ? getAdminUserStatusLabels(selected).join(', ')
            : getAdminUserStatusLabel(selected)
        }
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: itemHeight * 5 + itemPadding,
            },
          },
          disableAutoFocusItem: true,
        }}
        size={props.size}
      >
        {adminUserStatusOptions.map((status) => (
          <MenuItem key={status.value} value={status.value}>
            {props.multiple && typeof value === 'object' && (
              <Checkbox checked={!!value?.some((e) => e === status.value)} />
            )}
            <ListItemText primary={status.label} />
          </MenuItem>
        ))}
      </Select>
      {props.error && <FormHelperText error>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}

export default SelectAdminUserStatus;
