import { styled } from '@mui/material/styles';
import { themeStyles } from 'themes/styles';

export const Main = styled(
  (
    props: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLElement>,
      HTMLElement
    >
  ) => <main {...props} />
)(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  flexGrow: 1,
  background: 'rgb(240, 242, 248)',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up('md')]: {
    marginLeft: -themeStyles.mainLayoutDrawerWidth,
    width: `calc(100% - ${themeStyles.mainLayoutDrawerWidth}px)`,
  },
}));

export const MainContent = styled(
  (
    props: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  ) => <div {...props} />
)(({ theme }) => ({
  overflow: 'auto',
  height: '100%',
  padding: theme.spacing(3),
  paddingTop: 88,
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5),
    paddingTop: 104,
  },
}));
