import {
  Autocomplete,
  SxProps,
  TextField,
  Theme,
  debounce,
} from '@mui/material';
import { EmptyData, Loading } from 'components/common';
import { FindAllPeriodDto, PeriodCompactDto } from 'dto/period';
import { useFindAllPeriodCompact } from 'hooks/period';
import { useState } from 'react';

type PropsType = {
  name?: string;
  label?: React.ReactNode;
  margin?: 'dense' | 'normal' | 'none';
  fullWidth?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  value?: number;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  setFieldValue?: (value: number | null) => void;
  size?: 'small' | 'medium';
  width?: number;
  defaultSelected?: PeriodCompactDto;
  allowClear?: boolean;
  sx?: SxProps<Theme>;
};

function SelectPeriod(props: PropsType) {
  const [selected, setSelected] = useState<PeriodCompactDto | null>(
    props.defaultSelected ?? null
  );
  const [filters, setFilters] = useState<FindAllPeriodDto>({
    keyword: '',
  });
  const { infiniteQuery, rows, count, loading } = useFindAllPeriodCompact({
    filters: filters,
  });

  const debounceSearch = debounce((value?: string) => {
    setFilters((prev) => ({
      ...prev,
      keyword: value,
    }));
  }, 1000);

  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          label={props.label}
          margin={props.margin}
          error={props.error}
          helperText={props.helperText}
        />
      )}
      disableClearable={!props.allowClear}
      fullWidth={props.fullWidth}
      options={rows}
      filterOptions={() => rows}
      loading={loading}
      loadingText={<Loading height={100} />}
      getOptionLabel={(option?: PeriodCompactDto) => option?.name ?? ''}
      value={selected}
      onBlur={props.onBlur}
      onChange={(e, value) => {
        setSelected(value);
        if (props.setFieldValue) {
          props.setFieldValue(value?.id ?? null);
        }
      }}
      onInputChange={(e, value, reason) => {
        if (reason !== 'reset') {
          debounceSearch(value);
        }
      }}
      noOptionsText={<EmptyData />}
      ListboxProps={{
        onScroll: (e: React.SyntheticEvent) => {
          const listboxNode = e.currentTarget;

          if (
            Math.round(listboxNode.scrollTop + listboxNode.clientHeight) ===
              Math.round(listboxNode.scrollHeight) &&
            !loading &&
            rows.length < count
          ) {
            infiniteQuery.fetchNextPage();
          }
        },
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      sx={props.sx}
      size={props.size}
    />
  );
}

export default SelectPeriod;
