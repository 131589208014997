import { LoadingButton } from '@mui/lab';
import { CustomizedDialog } from 'components/common';
import { PasswordInput } from 'components/input';
import {
  UpdateAdminUserDto,
  updatePasswordAdminUserSchema,
} from 'dto/admin-users';
import { Formik } from 'formik';
import { useUpdateAdminUser } from 'hooks/admin-users';
import { locales } from 'utils/constants';

type PropsType = {
  selectedId: number;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

function UpdatePasswordAdminUserDialog(props: PropsType) {
  const { mutate, isLoading } = useUpdateAdminUser({ id: props.selectedId });

  const initialValues = updatePasswordAdminUserSchema.cast(
    {}
  ) as UpdateAdminUserDto;

  function onSubmit(values: UpdateAdminUserDto): void {
    mutate(values, {
      onSuccess: () => {
        props.onSuccess();
      },
    });
  }

  return (
    <CustomizedDialog
      open={props.open}
      onClose={props.onClose}
      title={locales.updatePassword}
      footer={[
        <LoadingButton
          key="cancel"
          color="error"
          variant="outlined"
          loading={isLoading}
          onClick={props.onClose}
        >
          {locales.cancel}
        </LoadingButton>,
        <LoadingButton
          key="submit"
          color="primary"
          variant="contained"
          type="submit"
          form="PasswordAdminUserForm"
          loading={isLoading}
        >
          {locales.submit}
        </LoadingButton>,
      ]}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={updatePasswordAdminUserSchema}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form id="PasswordAdminUserForm" noValidate onSubmit={handleSubmit}>
            <PasswordInput
              name="password"
              label={locales.password}
              fullWidth
              margin="normal"
              variant="outlined"
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && (errors.password as String)}
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />
          </form>
        )}
      </Formik>
    </CustomizedDialog>
  );
}

export default UpdatePasswordAdminUserDialog;
