import { Close } from '@mui/icons-material';
import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import { useState } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

type PropsType = {
  open?: boolean;
  onClose?: () => void;
  title?: React.ReactNode | string;
  children?: React.ReactNode;
  footer?: React.ReactNode | React.ReactNode[];
  maxWidth?: Breakpoint | false;
};

function CustomizedDialog(props: PropsType) {
  const [open, setOpen] = useState(props.open ?? false);

  const handleClose = () => {
    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <BootstrapDialog
      onClose={(e, reason) => {
        if (!reason) {
          handleClose();
        }
      }}
      aria-labelledby="CustomizedDialogTitle"
      open={props.open ?? open}
      maxWidth={props.maxWidth}
      fullWidth
      disableEscapeKeyDown
    >
      <DialogTitle
        sx={{ m: 0, p: 2, fontSize: '1.25rem', minHeight: 64 }}
        id="CustomizedDialogTitle"
      >
        {props.title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      {props.children && (
        <DialogContent dividers>{props.children}</DialogContent>
      )}
      {props.footer && <DialogActions>{props.footer}</DialogActions>}
    </BootstrapDialog>
  );
}

export default CustomizedDialog;
