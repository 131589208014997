import { Clear } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SxProps,
  Theme,
} from '@mui/material';
import {
  FORM_INSTANCE_RESULT,
  getFormInstanceResult,
} from 'utils/enums/form-instance-result.enum';

const itemHeight = 48;
const itemPadding = 8;

type PropsType = {
  name?: string;
  label?: React.ReactNode;
  margin?: 'dense' | 'normal' | 'none';
  fullWidth?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  value?: boolean;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  onChange?: (e: React.ChangeEvent<any>) => void;
  sx?: SxProps<Theme>;
  size?: 'small' | 'medium';
  allowClear?: boolean;
};

function SelectFormInstanceResult(props: PropsType) {
  return (
    <FormControl
      margin={props.margin}
      fullWidth={props.fullWidth}
      sx={props.sx}
      size={props.size}
    >
      <InputLabel id="SelectFormInstanceResultLabel">{props.label}</InputLabel>
      <Select
        id="SelectFormInstanceResult"
        labelId="SelectFormInstanceResultLabel"
        input={
          <OutlinedInput
            label={props.label}
            name={props.name}
            onBlur={props.onBlur}
            onChange={props.onChange}
          />
        }
        value={props.value ?? ''}
        renderValue={(selected) => getFormInstanceResult(selected)}
        style={{ padding: 0 }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: itemHeight * 5 + itemPadding,
            },
          },
          disableAutoFocusItem: true,
        }}
        IconComponent={
          props.allowClear && props.value !== undefined
            ? () => (
                <IconButton
                  className="right-[4px]"
                  size="small"
                  onClick={props.onChange}
                >
                  <Clear />
                </IconButton>
              )
            : undefined
        }
      >
        {Object.values(FORM_INSTANCE_RESULT).map((gender) => (
          <MenuItem key={gender.label} value={gender.value as any}>
            <ListItemText primary={gender.label} />
          </MenuItem>
        ))}
      </Select>
      {props.error && <FormHelperText error>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}

export default SelectFormInstanceResult;
