import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { CustomizedDialog, Loading } from 'components/common';
import dayjs from 'dayjs';
import { UpdateMyProfileDto, updateMyProfileSchema } from 'dto/admin-users';
import { Formik } from 'formik';
import { useGetMyProfile, useUpdateMyProfile } from 'hooks/admin-users';
import { dateFormat, locales } from 'utils/constants';

type PropsType = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

function UpdateMyProfileDialog(props: PropsType) {
  const { mutate, isLoading } = useUpdateMyProfile();
  const query = useGetMyProfile();

  const initialValues = updateMyProfileSchema.cast(query.data, {
    stripUnknown: true,
    assert: false,
  }) as UpdateMyProfileDto;

  function onSubmit(values: UpdateMyProfileDto): void {
    mutate(values, {
      onSuccess: () => {
        props.onSuccess();
      },
    });
  }

  return (
    <CustomizedDialog
      open={props.open}
      onClose={props.onClose}
      title={locales.update}
      footer={[
        <LoadingButton
          key="cancel"
          color="error"
          variant="outlined"
          loading={isLoading}
          onClick={props.onClose}
        >
          {locales.cancel}
        </LoadingButton>,
        <LoadingButton
          key="submit"
          color="primary"
          variant="contained"
          type="submit"
          form="ProfileForm"
          loading={isLoading}
        >
          {locales.submit}
        </LoadingButton>,
      ]}
    >
      {query.isLoading ? (
        <Loading height={400} />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={updateMyProfileSchema}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
          }) => (
            <form id="ProfileForm" noValidate onSubmit={handleSubmit}>
              <TextField
                name="fullName"
                label={locales.fullName}
                fullWidth
                margin="normal"
                variant="outlined"
                error={Boolean(touched.fullName && errors.fullName)}
                helperText={touched.fullName && (errors.fullName as String)}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
                required
              />
              <TextField
                name="phoneNumber"
                label={locales.phoneNumber}
                fullWidth
                margin="normal"
                variant="outlined"
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                helperText={
                  touched.phoneNumber && (errors.phoneNumber as String)
                }
                value={values.phoneNumber ?? ''}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Stack direction="row" spacing={2} useFlexGap>
                <DatePicker
                  name="birthday"
                  label={locales.birthday}
                  format={dateFormat}
                  value={values.birthday ? dayjs(values.birthday) : null}
                  onChange={(value: dayjs.Dayjs | null) =>
                    setFieldValue('birthday', value?.toDate(), true)
                  }
                  slotProps={{
                    textField: {
                      margin: 'normal',
                      variant: 'outlined',
                      error: Boolean(touched.birthday && errors.birthday),
                      helperText:
                        touched.birthday && (errors.birthday as String),
                      onBlur: handleBlur,
                    },
                  }}
                  disableFuture
                  sx={{ width: 300 }}
                />
                <TextField
                  name="gender"
                  label={locales.gender}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={Boolean(touched.gender && errors.gender)}
                  helperText={touched.gender && (errors.gender as String)}
                  value={values.gender ?? ''}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Stack>
            </form>
          )}
        </Formik>
      )}
    </CustomizedDialog>
  );
}

export default UpdateMyProfileDialog;
