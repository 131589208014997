import { CustomizedDrawer, Descriptions, Loading } from 'components/common';
import dayjs from 'dayjs';
import { useFindOneAdminUser } from 'hooks/admin-users';
import { dateFormat, dateTimeFormat, locales } from 'utils/constants';
import { getAdminUserStatusLabel } from 'utils/enums';

type PropsType = {
  selectedId: number;
  open: boolean;
  onClose: () => void;
  footer?: React.ReactNode | React.ReactNode[];
};

function AdminUserInfoDrawer(props: PropsType) {
  const { data, isLoading } = useFindOneAdminUser({
    id: props.selectedId,
    enabled: props.open,
  });

  function renderInfo() {
    if (isLoading) {
      return <Loading height={400} />;
    }

    if (data) {
      return (
        <Descriptions
          items={[
            {
              label: locales.fullName,
              value: data.fullName,
            },
            {
              label: locales.email,
              value: data.email,
            },
            {
              label: locales.phoneNumber,
              value: data.phoneNumber,
            },
            {
              label: locales.birthday,
              value: data.birthday && dayjs(data.birthday).format(dateFormat),
            },
            {
              label: locales.gender,
              value: data.gender,
            },
            {
              label: locales.email,
              value: data.email,
            },
            {
              label: locales.role,
              value: data.adminRole?.name,
            },
            {
              label: locales.status,
              value: getAdminUserStatusLabel(data.status),
            },
            {
              label: locales.createdAt,
              value: dayjs(data.createdAt).format(dateTimeFormat),
            },
            {
              label: locales.updatedAt,
              value: dayjs(data.updatedAt).format(dateTimeFormat),
            },
          ]}
        />
      );
    }
  }

  return (
    <CustomizedDrawer
      anchor="right"
      open={props.open}
      onClose={props.onClose}
      title={locales.info}
      footer={props.footer}
    >
      {renderInfo()}
    </CustomizedDrawer>
  );
}

export default AdminUserInfoDrawer;
