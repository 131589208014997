import { Clear } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import {
  PERMISSION,
  getPermissionLabels,
  permissionOptions,
} from 'utils/enums';

const itemHeight = 48;
const itemPadding = 8;

type PropsType = {
  name?: string;
  label?: React.ReactNode;
  margin?: 'dense' | 'normal' | 'none';
  fullWidth?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  value?: PERMISSION[];
  onBlur?: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  onChange?: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  setFieldValue?: (value?: PERMISSION[]) => void;
  allowClear?: boolean;
};

function SelectPermission(props: PropsType) {
  const isAllowClear = !!(
    props.allowClear &&
    typeof props.value?.length === 'number' &&
    props.value?.length > 0
  );

  return (
    <FormControl margin={props.margin} fullWidth={props.fullWidth}>
      <InputLabel id="SelectPermissionLabel">{props.label}</InputLabel>
      <Select
        id="SelectPermission"
        labelId="SelectPermissionLabel"
        multiple
        input={
          <OutlinedInput
            label={props.label}
            name={props.name}
            onBlur={props.onBlur}
            onChange={props.onChange}
          />
        }
        value={props.value ?? []}
        renderValue={(selected) => getPermissionLabels(selected).join(', ')}
        style={{ padding: 0 }}
        endAdornment={
          isAllowClear && (
            <IconButton
              className="absolute right-6"
              size="small"
              onClick={(e) => {
                if (props.setFieldValue) {
                  props.setFieldValue(undefined);
                }
              }}
            >
              <Clear />
            </IconButton>
          )
        }
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: itemHeight * 5 + itemPadding,
            },
          },
          disableAutoFocusItem: true,
        }}
        SelectDisplayProps={{
          style: {
            paddingRight: isAllowClear ? 48 : 0,
          },
        }}
      >
        {permissionOptions.map((permission) => (
          <MenuItem key={permission.value} value={permission.value}>
            <Checkbox
              checked={!!props.value?.some((e) => e === permission.value)}
            />
            <ListItemText primary={permission.label} />
          </MenuItem>
        ))}
      </Select>
      {props.error && <FormHelperText error>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}

export default SelectPermission;
