import { Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { PasswordInput } from 'components/input';
import { SelectAdminRole, SelectAdminUserStatus } from 'components/select';
import dayjs from 'dayjs';
import { AdminUserDto } from 'dto/admin-users';
import { Formik, FormikValues } from 'formik';
import { dateFormat, locales } from 'utils/constants';

type PropsType<T> = {
  initialValues: T;
  onSubmit: (values: T) => void;
  validationSchema: any;
  enableReinitialize?: boolean;
  omitPassword?: boolean;
  defaultValues?: AdminUserDto;
};

function AdminUserForm<T extends FormikValues>(props: PropsType<T>) {
  return (
    <Formik
      enableReinitialize={props.enableReinitialize}
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validationSchema={props.validationSchema}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values,
      }) => (
        <form id="AdminUserForm" noValidate onSubmit={handleSubmit}>
          <TextField
            name="fullName"
            label={locales.fullName}
            fullWidth
            margin="normal"
            variant="outlined"
            error={Boolean(touched.fullName && errors.fullName)}
            helperText={touched.fullName && (errors.fullName as String)}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.fullName}
            required
          />
          <TextField
            name="email"
            label={locales.email}
            fullWidth
            margin="normal"
            variant="outlined"
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && (errors.email as String)}
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            required
          />
          {!props.omitPassword && (
            <PasswordInput
              name="password"
              label={locales.password}
              fullWidth
              margin="normal"
              variant="outlined"
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && (errors.password as String)}
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />
          )}
          <TextField
            name="phoneNumber"
            label={locales.phoneNumber}
            fullWidth
            margin="normal"
            variant="outlined"
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && (errors.phoneNumber as String)}
            value={values.phoneNumber ?? ''}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Stack direction="row" spacing={2} useFlexGap>
            <DatePicker
              name="birthday"
              label={locales.birthday}
              format={dateFormat}
              value={values.birthday ? dayjs(values.birthday) : null}
              onChange={(value: dayjs.Dayjs | null) =>
                setFieldValue('birthday', value?.toDate(), true)
              }
              slotProps={{
                textField: {
                  margin: 'normal',
                  variant: 'outlined',
                  error: Boolean(touched.birthday && errors.birthday),
                  helperText: touched.birthday && (errors.birthday as String),
                  onBlur: handleBlur,
                },
              }}
              disableFuture
              sx={{ width: 300 }}
            />
            <TextField
              name="gender"
              label={locales.gender}
              fullWidth
              margin="normal"
              variant="outlined"
              error={Boolean(touched.gender && errors.gender)}
              helperText={touched.gender && (errors.gender as String)}
              value={values.gender ?? ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Stack>
          <SelectAdminRole
            name="adminRoleId"
            label={locales.role}
            fullWidth
            margin="normal"
            error={Boolean(touched.adminRoleId && errors.adminRoleId)}
            helperText={touched.adminRoleId && (errors.adminRoleId as String)}
            value={values.adminRoleId}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={(value) => setFieldValue('adminRoleId', value, true)}
            defaultSelected={props.defaultValues?.adminRole}
            allowClear
          />
          <SelectAdminUserStatus
            name="status"
            label={locales.status}
            fullWidth
            margin="normal"
            error={Boolean(touched.status && errors.status)}
            helperText={touched.status && (errors.status as String)}
            value={values.status}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </form>
      )}
    </Formik>
  );
}

export default AdminUserForm;
