import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { themeStyles } from 'themes/styles';

type PropsType = {
  isShowText?: boolean;
  theme?: 'dark' | 'light';
};

function Logo(props: PropsType) {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div id="Logo">
      <Stack direction="row" spacing={1}>
        <img alt="Logo" src="/logo.jpg" width={40} height={40} />
        {(props.isShowText || matchUpMd) && (
          <Typography
            variant="h3"
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
            sx={
              props.theme === 'dark'
                ? { color: themeStyles.textLight }
                : {
                    background: themeStyles.backgroundTextLogo,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }
            }
          >
            Sàng lọc sớm
          </Typography>
        )}
      </Stack>
    </div>
  );
}

export default Logo;
