import { Button } from '@mui/material';
import { CustomizedDrawer, Descriptions, Loading } from 'components/common';
import dayjs from 'dayjs';
import { useGetMyProfile } from 'hooks/admin-users';
import { useState } from 'react';
import { dateFormat, dateTimeFormat, locales } from 'utils/constants';
import { getAdminUserStatusLabel } from 'utils/enums';
import UpdateMyPasswordDialog from './UpdateMyPasswordDialog';
import UpdateMyProfileDialog from './UpdateMyProfileDialog';

type PropsType = {
  open: boolean;
  onClose: () => void;
};

function ProfileDrawer(props: PropsType) {
  const { data, isLoading } = useGetMyProfile();

  const [openUpdate, setOpenUpdate] = useState(false);
  const [openUpdatePassword, setOpenUpdatePassword] = useState(false);

  function renderInfo() {
    if (isLoading) {
      return <Loading height={400} />;
    }

    if (data) {
      return (
        <Descriptions
          items={[
            {
              label: locales.fullName,
              value: data.fullName,
            },
            {
              label: locales.email,
              value: data.email,
            },
            {
              label: locales.phoneNumber,
              value: data.phoneNumber,
            },
            {
              label: locales.birthday,
              value: data.birthday && dayjs(data.birthday).format(dateFormat),
            },
            {
              label: locales.gender,
              value: data.gender,
            },
            {
              label: locales.email,
              value: data.email,
            },
            {
              label: locales.role,
              value: data.adminRole?.name,
            },
            {
              label: locales.status,
              value: getAdminUserStatusLabel(data.status),
            },
            {
              label: locales.createdAt,
              value: dayjs(data.createdAt).format(dateTimeFormat),
            },
            {
              label: locales.updatedAt,
              value: dayjs(data.updatedAt).format(dateTimeFormat),
            },
          ]}
        />
      );
    }
  }

  return (
    <CustomizedDrawer
      anchor="right"
      open={props.open}
      onClose={props.onClose}
      title={locales.info}
      footer={[
        <Button
          key="update-password"
          color="success"
          variant="contained"
          onClick={() => setOpenUpdatePassword(true)}
        >
          {locales.updatePassword}
        </Button>,
        <Button
          key="update"
          color="primary"
          variant="contained"
          onClick={() => setOpenUpdate(true)}
        >
          {locales.update}
        </Button>,
      ]}
    >
      {renderInfo()}
      <UpdateMyProfileDialog
        open={openUpdate}
        onClose={() => setOpenUpdate(false)}
        onSuccess={() => setOpenUpdate(false)}
      />
      <UpdateMyPasswordDialog
        open={openUpdatePassword}
        onClose={() => setOpenUpdatePassword(false)}
        onSuccess={() => setOpenUpdatePassword(false)}
      />
    </CustomizedDrawer>
  );
}

export default ProfileDrawer;
