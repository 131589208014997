import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import React, { useState } from 'react';
import { theme } from 'themes';

type PropsType = {
  id?: string;
  name?: string;
  label?: React.ReactNode;
  value?: string;
  onBlur?: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  onChange?: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  error?: boolean;
  helperText?: React.ReactNode;
  fullWidth?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  variant?: 'standard' | 'outlined' | 'filled';
  required?: boolean;
};

function PasswordInput(props: PropsType) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl
      margin={props.margin}
      fullWidth={props.fullWidth}
      error={props.error}
      sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
      variant={props.variant}
      required={props.required}
    >
      <InputLabel htmlFor={props.id ?? "PasswordInput"}>{props.label}</InputLabel>
      <OutlinedInput
        id={props.id ?? "PasswordInput"}
        name={props.name}
        label={props.label}
        type={showPassword ? 'text' : 'password'}
        value={props.value}
        onBlur={props.onBlur}
        onChange={props.onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword((showPassword) => !showPassword)}
              edge="end"
              size="small"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        autoComplete="new-password"
      />
      {props.error && <FormHelperText error>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}

export default PasswordInput;
