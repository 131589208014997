import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { formatNumber } from 'utils/common';
import { CONTROL_TYPE } from 'utils/enums';

type PropsType = {
  controlType?: CONTROL_TYPE;
  value?: any;
  valuelist?: string;
  name?: string;
  className?: string;
  errors?: FormikErrors<any>;
  touched?: FormikTouched<any>;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  onChange?: (e: React.ChangeEvent<any>) => void;
  isDisplayValue?: boolean;
};

function ControlType(props: PropsType) {
  const {
    controlType,
    value,
    valuelist,
    name,
    className = 'inline ml-auto float-right',
    errors,
    touched,
    onBlur,
    onChange,
    isDisplayValue,
  } = props;
  const options = valuelist?.split(';') ?? [];

  switch (controlType) {
    case CONTROL_TYPE.RADIO_BUTTON:
      return (
        <RadioGroup
          row
          name={name}
          className={className}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        >
          {options.map((option) => {
            const [value, label] = option.split('::');

            return (
              <FormControlLabel
                key={option}
                value={value}
                control={<Radio size="small" />}
                label={label}
              />
            );
          })}
        </RadioGroup>
      );
    case CONTROL_TYPE.TEXT:
      return isDisplayValue ? (
        <Typography className={className}>{value}</Typography>
      ) : (
        <TextField
          name={name}
          value={value}
          className={className}
          onBlur={onBlur}
          onChange={onChange}
          error={Boolean(touched?.name && errors?.name)}
          helperText={touched?.name && (errors?.name as String)}
          fullWidth
        />
      );
    case CONTROL_TYPE.NUMBER:
      return isDisplayValue ? (
        <Typography className={className}>
          {isNaN(value) ? value : formatNumber(value)}
        </Typography>
      ) : (
        <TextField
          name={name}
          value={value}
          className={className}
          onBlur={onBlur}
          onChange={onChange}
          error={Boolean(touched?.name && errors?.name)}
          helperText={touched?.name && (errors?.name as String)}
          fullWidth
          type="number"
        />
      );
    case CONTROL_TYPE.CHECKBOX:
      return (
        <FormControl
          className={className}
          error={Boolean(touched?.name && errors?.name)}
        >
          <Checkbox
            name={name}
            checked={value === 'true'}
            onBlur={onBlur}
            onChange={(e) => {
              if (onChange) {
                e.target.value = String(e.target.checked);
                onChange(e);
              }
            }}
          />
          {Boolean(touched?.name && errors?.name) && (
            <FormHelperText error>
              {touched?.name && (errors?.name as String)}
            </FormHelperText>
          )}
        </FormControl>
      );
    case CONTROL_TYPE.COMBOBOX:
      let selectedValue = '';
      for (const option of options) {
        const [optionValue, optionLabel] = option.split('::');

        if (optionValue === value) {
          selectedValue = optionLabel;
          break;
        }
      }

      return isDisplayValue ? (
        <Typography className={className}>{selectedValue}</Typography>
      ) : (
        <FormControl
          className={className}
          error={Boolean(touched?.name && errors?.name)}
          fullWidth
        >
          <Select
            fullWidth
            value={value}
            input={
              <OutlinedInput
                name={props.name}
                onBlur={props.onBlur}
                onChange={props.onChange}
              />
            }
          >
            {options?.map((option) => {
              const [value, label] = option.split('::');

              return (
                <MenuItem key={value} value={value}>
                  <ListItemText primary={label} />
                </MenuItem>
              );
            })}
          </Select>
          {Boolean(touched?.name && errors?.name) && (
            <FormHelperText error>
              {touched?.name && (errors?.name as String)}
            </FormHelperText>
          )}
        </FormControl>
      );
    default:
      return null;
  }
}

export default ControlType;
