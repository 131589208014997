import { DefaultResponseDto, PagingDataDto } from 'dto/common';
import {
  CreateProgramDto,
  FindAllProgramDto,
  ProgramCompactDto,
  ProgramDto,
  UpdateProgramDto,
} from 'dto/program';
import axiosClient from 'utils/axios-client';

export const programAPI = {
  create(createProgramDto: CreateProgramDto): Promise<ProgramDto> {
    return axiosClient.post('program', createProgramDto);
  },
  findAll(
    findAllProgramDto: FindAllProgramDto
  ): Promise<PagingDataDto<ProgramDto>> {
    return axiosClient.get('program', { params: findAllProgramDto });
  },
  findAllCompact(
    findAllProgramDto: FindAllProgramDto
  ): Promise<PagingDataDto<ProgramCompactDto>> {
    return axiosClient.get('program/compact', { params: findAllProgramDto });
  },
  findOne(id: number): Promise<ProgramDto> {
    return axiosClient.get(`program/${id}`);
  },
  update(id: number, updateProgramDto: UpdateProgramDto): Promise<ProgramDto> {
    return axiosClient.put(`program/${id}`, updateProgramDto);
  },
  delete(id: number): Promise<DefaultResponseDto> {
    return axiosClient.delete(`program/${id}`);
  },
};
