import { formAPI } from 'api/form';
import { UpdateFormDto } from 'dto/form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { locales } from 'utils/constants';
import { QUERY_KEY } from 'utils/enums';

type PropsType = {
  id: number;
};

export function useUpdateForm(props: PropsType) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (payload: UpdateFormDto) => formAPI.update(props.id, payload),
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEY.FIND_ONE_FORM, props.id], data);
      toast(locales.updateSuccess, { type: 'success' });
    },
  });

  return mutation;
}
