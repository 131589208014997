import { programAPI } from 'api/program';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { locales } from 'utils/constants';
import { QUERY_KEY } from 'utils/enums';

export function useCreateProgram() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: programAPI.create,
    onSuccess: (data) => {
      queryClient.setQueryData(QUERY_KEY.FIND_ONE_PROGRAM, data);
      toast(locales.createSuccess, { type: 'success' });
    },
  });

  return mutation;
}
