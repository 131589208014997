import { DefaultResponseDto, PagingDataDto } from 'dto/common';
import {
  CreateFormDto,
  FindAllFormDto,
  FormCompactDto,
  FormDto,
  FormWithoutMemberDto,
  UpdateFormDto,
} from 'dto/form';
import axiosClient from 'utils/axios-client';

export const formAPI = {
  create(createFormDto: CreateFormDto): Promise<FormDto> {
    return axiosClient.post('form', createFormDto);
  },
  findAll(
    findAllFormDto: FindAllFormDto
  ): Promise<PagingDataDto<FormWithoutMemberDto>> {
    return axiosClient.get('form', { params: findAllFormDto });
  },
  findAllCompact(
    findAllFormDto: FindAllFormDto
  ): Promise<PagingDataDto<FormCompactDto>> {
    return axiosClient.get('form/compact', { params: findAllFormDto });
  },
  findOne(id: number): Promise<FormDto> {
    return axiosClient.get(`form/${id}`);
  },
  update(id: number, updateFormDto: UpdateFormDto): Promise<FormDto> {
    return axiosClient.put(`form/${id}`, updateFormDto);
  },
  delete(id: number): Promise<DefaultResponseDto> {
    return axiosClient.delete(`form/${id}`);
  },
};
