import { LoadingButton } from '@mui/lab';
import { CustomizedDialog, Loading } from 'components/common';
import { UpdateAdminUserDto, updateAdminUserSchema } from 'dto/admin-users';
import { useFindOneAdminUser, useUpdateAdminUser } from 'hooks/admin-users';
import AdminUserForm from './AdminUserForm';
import { locales } from 'utils/constants';

type PropsType = {
  selectedId: number;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

function UpdateAdminUserDialog(props: PropsType) {
  const { mutate, isLoading } = useUpdateAdminUser({ id: props.selectedId });
  const query = useFindOneAdminUser({
    id: props.selectedId,
    enabled: props.open,
  });

  const initialValues = updateAdminUserSchema.cast(query.data, {
    stripUnknown: true,
    assert: false,
  }) as UpdateAdminUserDto;

  function onSubmit(values: UpdateAdminUserDto): void {
    mutate(values, {
      onSuccess: () => {
        props.onSuccess();
      },
    });
  }

  return (
    <CustomizedDialog
      open={props.open}
      onClose={props.onClose}
      title={locales.update}
      footer={[
        <LoadingButton
          key="cancel"
          color="error"
          variant="outlined"
          loading={isLoading}
          onClick={props.onClose}
        >
          {locales.cancel}
        </LoadingButton>,
        <LoadingButton
          key="submit"
          color="primary"
          variant="contained"
          type="submit"
          form="AdminUserForm"
          loading={isLoading}
        >
          {locales.submit}
        </LoadingButton>,
      ]}
    >
      {query.isLoading ? (
        <Loading height={400} />
      ) : (
        <AdminUserForm<UpdateAdminUserDto>
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={updateAdminUserSchema}
          omitPassword
          defaultValues={query.data}
        />
      )}
    </CustomizedDialog>
  );
}

export default UpdateAdminUserDialog;
