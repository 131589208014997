import { Box, useMediaQuery, useTheme } from '@mui/material';
import ErrorBoundary from 'pages/500';
import { ReactNode, memo, useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { PATH } from 'routes/constants';
import { localStorageKey } from 'utils/constants';
import Sidebar from './Sidebar';
import Header from './Header';
import { Main, MainContent } from './Main';
import { Loadable } from 'components/common';

type PropsType = {
  children?: ReactNode;
};

function MainLayout(props: PropsType) {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const [drawerOpen, setDrawerOpen] = useState(matchUpMd);

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.key === localStorageKey.accessToken && !e.newValue) {
        localStorage.removeItem(localStorageKey.accessToken);
        localStorage.removeItem(localStorageKey.refreshToken);
        navigate(PATH.LOGIN, { replace: true });
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, []);

  if (!localStorage.getItem(localStorageKey.accessToken)) {
    return <Navigate to={PATH.LOGIN} replace />;
  }

  return (
    <Box
      component="section"
      id="MainLayout"
      sx={{ display: 'flex', width: '100%' }}
    >
      <Header
        handleDrawerOpen={() => {
          setDrawerOpen((drawerOpen) => !drawerOpen);
        }}
      />
      <Sidebar
        drawerOpen={drawerOpen}
        handleDrawerOpen={() => {
          setDrawerOpen((drawerOpen) => !drawerOpen);
        }}
      />
      <Main
        id="MainLayout-Main"
        style={{
          ...(drawerOpen && {
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
            marginRight: 'inherit',
          }),
        }}
      >
        <MainContent id="MainLayout-MainContent">
          <ErrorBoundary>
            <Loadable>{props.children ?? <Outlet />}</Loadable>
          </ErrorBoundary>
        </MainContent>
      </Main>
    </Box>
  );
}

export default memo(MainLayout);
