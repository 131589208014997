import { periodAPI } from 'api/period';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { locales } from 'utils/constants';

export function useDeletePeriod() {
  const mutation = useMutation({
    mutationFn: periodAPI.delete,
    onSuccess: () => {
      toast(locales.deleteSuccess, { type: 'success' });
    },
  });

  return mutation;
}
