import { adminUsersAPI } from 'api/admin-users';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { locales } from 'utils/constants';

export function useDeleteAdminUser() {
  const mutation = useMutation({
    mutationFn: adminUsersAPI.delete,
    onSuccess: () => {
      toast(locales.deleteSuccess, { type: 'success' });
    },
  });

  return mutation;
}
