import { LoadingButton } from '@mui/lab';
import { CustomizedDialog, Loading } from 'components/common';
import { PasswordInput } from 'components/input';
import { UpdateMyPasswordDto, updateMyPasswordSchema } from 'dto/admin-users';
import { Formik } from 'formik';
import { useGetMyProfile, useUpdateMyPassword } from 'hooks/admin-users';
import { locales } from 'utils/constants';

type PropsType = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

function UpdateMyPasswordDialog(props: PropsType) {
  const { mutate, isLoading } = useUpdateMyPassword();
  const query = useGetMyProfile();

  const initialValues: UpdateMyPasswordDto & { confirmPassword: string } = {
    currentPassword: '',
    password: '',
    confirmPassword: '',
  };

  function onSubmit(values: UpdateMyPasswordDto): void {
    mutate(values, {
      onSuccess: () => {
        props.onSuccess();
      },
    });
  }

  return (
    <CustomizedDialog
      open={props.open}
      onClose={props.onClose}
      title={locales.update}
      footer={[
        <LoadingButton
          key="cancel"
          color="error"
          variant="outlined"
          loading={isLoading}
          onClick={props.onClose}
        >
          {locales.cancel}
        </LoadingButton>,
        <LoadingButton
          key="submit"
          color="primary"
          variant="contained"
          type="submit"
          form="PasswordForm"
          loading={isLoading}
        >
          {locales.submit}
        </LoadingButton>,
      ]}
    >
      {query.isLoading ? (
        <Loading height={400} />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={updateMyPasswordSchema}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form id="PasswordForm" noValidate onSubmit={handleSubmit}>
              <PasswordInput
                id="currentPassword"
                name="currentPassword"
                label={locales.currentPassword}
                fullWidth
                margin="normal"
                variant="outlined"
                error={Boolean(
                  touched.currentPassword && errors.currentPassword
                )}
                helperText={
                  touched.currentPassword && (errors.currentPassword as String)
                }
                value={values.currentPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />
              <PasswordInput
                id="password"
                name="password"
                label={locales.password}
                fullWidth
                margin="normal"
                variant="outlined"
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && (errors.password as String)}
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />
              <PasswordInput
                id="confirmPassword"
                name="confirmPassword"
                label={locales.confirmPassword}
                fullWidth
                margin="normal"
                variant="outlined"
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                helperText={
                  touched.confirmPassword && (errors.confirmPassword as String)
                }
                value={values.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />
            </form>
          )}
        </Formik>
      )}
    </CustomizedDialog>
  );
}

export default UpdateMyPasswordDialog;
