export const themeStyles = {
  primary: '#3366ff',
  primary100: '#4c6fff',

  primaryDark: '#0043a9',
  primaryLight: '#3380f4',

  secondary: '#425466',
  secondaryDark: '#272f33',
  secondaryLight: '#60696d',

  error: '#ff413a',
  errorDark: '#a20e00',
  errorLight: '#ec4333',

  warning: '#f4a100',
  warningDark: '#aa7000',
  warningLight: '#f6b333',

  info: '#00cfd5',
  infoDark: '#009095',
  infoLight: '#33d8dd',

  success: '#00ac69',
  successDark: '#007849',
  successLight: '#33bc87',

  blue100: '#DAECFF',
  blue200: '#80BFFF',
  blue400: '#3399FF',
  blue500: '#007FFF',
  blue600: '#0072E5',
  blue700: '#0059B2',

  grey50: '#F3F6F9',
  grey100: '#E5EAF2',
  grey200: '#DAE2ED',
  grey300: '#425466',
  grey400: '#B0B8C4',
  grey500: '#9DA8B7',
  grey600: '#6B7A90',
  grey700: '#434D5B',
  grey800: '#303740',
  grey900: '#1C2025',

  bg100: '#f8f8f9',

  textPrimary: '#242c3a',
  textSecondary: '#1b212c',
  textDark: '#12171e',
  textHint: '#00000061',
  textLight: '#ffffff',

  backgound: '#f0f2f8',
  backgoundLayout:
    'linear-gradient(45deg, rgba(86, 58, 250, 0.9) 0%, rgba(116, 15, 214, 0.9) 100%), url("./hero-bg.jpg") center center no-repeat',
  backgroundTextLogo:
    '-webkit-linear-gradient(rgba(86, 58, 250, 0.9), rgba(116, 15, 214, 0.9))',

  paper: '#ffffff',
  paperDark: '#232b38',

  menuHover: '#e3f2fd',

  mainLayoutDrawerWidth: 280,
};
