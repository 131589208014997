import { formAPI } from 'api/form';
import { FormDto } from 'dto/form';
import { useQuery } from 'react-query';
import { QUERY_KEY } from 'utils/enums';

type PropsType = {
  id: number;
  enabled?: boolean;
  onSuccess?: (data: FormDto) => void;
};

export function useFindOneForm(props: PropsType) {
  const query = useQuery({
    queryKey: [QUERY_KEY.FIND_ONE_FORM, props.id],
    queryFn: () => formAPI.findOne(props.id),
    enabled: !!props.id || props.enabled,
    onSuccess: props.onSuccess,
  });

  return query;
}
