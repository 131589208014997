import { programAPI } from 'api/program';
import { UpdateProgramDto } from 'dto/program';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { locales } from 'utils/constants';
import { QUERY_KEY } from 'utils/enums';

type PropsType = {
  id: number;
};

export function useUpdateProgram(props: PropsType) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (payload: UpdateProgramDto) =>
      programAPI.update(props.id, payload),
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEY.FIND_ONE_PROGRAM, props.id], data);
      toast(locales.updateSuccess, { type: 'success' });
    },
  });

  return mutation;
}
