import { datasetAPI } from 'api/dataset';
import { UpdateDatasetDto } from 'dto/dataset';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { locales } from 'utils/constants';
import { QUERY_KEY } from 'utils/enums';

type PropsType = {
  id: number;
};

export function useUpdateDataset(props: PropsType) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (payload: UpdateDatasetDto) =>
      datasetAPI.update(props.id, payload),
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEY.FIND_ONE_DATASET, props.id], data);
      toast(locales.updateSuccess, { type: 'success' });
    },
  });

  return mutation;
}
