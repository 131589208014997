import { dataelementAPI } from 'api/dataelement';
import { FindAllDataelementDto } from 'dto/dataelement';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { QUERY_KEY } from 'utils/enums';

type PropsType = {
  filters: FindAllDataelementDto;
};

export function useFindAllDataelement(props: PropsType) {
  const query = useQuery({
    queryKey: QUERY_KEY.FIND_ALL_DATAELEMENT,
    queryFn: () => dataelementAPI.findAll(props.filters),
  });

  useEffect(() => {
    query.refetch();
  }, [props.filters]);

  return {
    query: query,
    count: query.data?.count ?? 0,
    rows: query.data?.rows ?? [],
    loading: query.isLoading || query.isFetching || query.isRefetching,
  };
}
