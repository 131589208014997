import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { theme } from 'themes';
import Loading from './Loading';

type PropsType = {
  primary: React.ReactNode;
  secondary: React.ReactNode;
  iconPrimary?: (props: SvgIconProps) => JSX.Element;
  footerData?: React.ReactNode;
  iconFooter?: (props: SvgIconProps) => JSX.Element;
  color: string;
  extra?: string;
  isLoading?: boolean;
};

const ReportCard = (props: PropsType) => {
  const {
    primary,
    secondary,
    iconPrimary: IconPrimary,
    color,
    footerData,
    iconFooter: IconFooter,
    extra,
  } = props;

  const primaryIcon = IconPrimary ? <IconPrimary fontSize="large" /> : null;
  const footerIcon = IconFooter ? <IconFooter /> : null;

  return (
    <Card>
      <CardContent>
        {props.isLoading ? (
          <Loading height={64} />
        ) : (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item flex={!primaryIcon ? 1 : undefined}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h3" sx={{ color: color }}>
                  {primary}
                </Typography>
                {extra && (
                  <Typography variant="h3" sx={{ color: color }}>
                    {extra}
                  </Typography>
                )}
              </Stack>
              <Typography variant="subtitle1" sx={{ marginTop: '.5rem' }}>
                {secondary}
              </Typography>
            </Grid>
            {primaryIcon && (
              <Grid item>
                <Typography variant="h2" sx={{ color: color }}>
                  {primaryIcon}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </CardContent>
      <Box sx={{ background: color }}>
        <Grid
          container
          justifyContent="space-between"
          sx={{
            textAlign: 'center',
            padding: theme.spacing(1.2),
            pl: 2.5,
            pr: 2.5,
            color: theme.palette.common.white,
          }}
        >
          <Grid item>
            <Typography variant="body2">{footerData}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{footerIcon}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default ReportCard;
