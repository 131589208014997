import { adminUsersAPI } from 'api/admin-users';
import { UpdateAdminUserDto } from 'dto/admin-users';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { locales } from 'utils/constants';
import { QUERY_KEY } from 'utils/enums';

type PropsType = {
  id: number;
};

export function useUpdateAdminUser(props: PropsType) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (payload: UpdateAdminUserDto) =>
      adminUsersAPI.update(props.id, payload),
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEY.FIND_ONE_ADMIN_USER, props.id], data);
      toast(locales.updateSuccess, { type: 'success' });
    },
  });

  return mutation;
}
