import { adminUsersAPI } from 'api/admin-users';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PATH } from 'routes/constants';
import { localStorageKey, locales } from 'utils/constants';
import { QUERY_KEY } from 'utils/enums';

export function useUpdateMyPassword() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: adminUsersAPI.updateMyPassword,
    onSuccess: () => {
      toast(locales.updatePasswordSuccess, { type: 'success' });
      localStorage.removeItem(localStorageKey.accessToken);
      localStorage.removeItem(localStorageKey.refreshToken);
      queryClient.setQueryData(QUERY_KEY.GET_MY_PROFILE, null);
      navigate(PATH.LOGIN, { replace: true });
    },
  });

  return mutation;
}
