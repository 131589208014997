import { DefaultResponseDto, PagingDataDto } from 'dto/common';
import {
  CreateOrgunitDto,
  FindAllOrgunitDto,
  OrgunitCompactDto,
  OrgunitDto,
  UpdateOrgunitDto,
} from 'dto/orgunit';
import axiosClient from 'utils/axios-client';

export const orgunitAPI = {
  create(createOrgunitDto: CreateOrgunitDto): Promise<OrgunitDto> {
    return axiosClient.post('orgunit', createOrgunitDto);
  },
  findAll(
    findAllOrgunitDto: FindAllOrgunitDto
  ): Promise<PagingDataDto<OrgunitDto>> {
    return axiosClient.get('orgunit', { params: findAllOrgunitDto });
  },
  findAllCompact(
    findAllOrgunitDto: FindAllOrgunitDto
  ): Promise<PagingDataDto<OrgunitCompactDto>> {
    return axiosClient.get('orgunit/compact', { params: findAllOrgunitDto });
  },
  findOne(id: number): Promise<OrgunitDto> {
    return axiosClient.get(`orgunit/${id}`);
  },
  update(id: number, updateOrgunitDto: UpdateOrgunitDto): Promise<OrgunitDto> {
    return axiosClient.put(`orgunit/${id}`, updateOrgunitDto);
  },
  delete(id: number): Promise<DefaultResponseDto> {
    return axiosClient.delete(`orgunit/${id}`);
  },
};
