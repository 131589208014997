import { dataelementAPI } from 'api/dataelement';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { locales } from 'utils/constants';
import { QUERY_KEY } from 'utils/enums';

export function useCreateDataelement() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: dataelementAPI.create,
    onSuccess: (data) => {
      queryClient.setQueryData(QUERY_KEY.FIND_ONE_DATAELEMENT, data);
      toast(locales.createSuccess, { type: 'success' });
    },
  });

  return mutation;
}
