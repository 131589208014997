import { formAPI } from 'api/form';
import { FindAllFormDto } from 'dto/form';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { QUERY_KEY } from 'utils/enums';

type PropsType = {
  filters: FindAllFormDto;
};

export function useFindAllForm(props: PropsType) {
  const query = useQuery({
    queryKey: QUERY_KEY.FIND_ALL_FORM,
    queryFn: () => formAPI.findAll(props.filters),
  });

  useEffect(() => {
    query.refetch();
  }, [props.filters]);

  return {
    query: query,
    count: query.data?.count ?? 0,
    rows: query.data?.rows ?? [],
    loading: query.isLoading || query.isFetching || query.isRefetching,
  };
}
