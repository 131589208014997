import { datasetAPI } from 'api/dataset';
import { FindAllDatasetDto } from 'dto/dataset';
import { useInfiniteQuery } from 'react-query';
import { defaultPageIndex, defaultCompactPageSize } from 'utils/constants';
import { QUERY_KEY } from 'utils/enums';

type PropsType = {
  filters: FindAllDatasetDto;
};

export function useFindAllDatasetCompact(props: PropsType) {
  const infiniteQuery = useInfiniteQuery({
    queryKey: [QUERY_KEY.FIND_ALL_DATASET_COMPACT, props.filters],
    queryFn: ({ pageParam = defaultPageIndex }) =>
      datasetAPI.findAllCompact({
        ...props.filters,
        pageIndex: pageParam,
        pageSize: defaultCompactPageSize,
      }),
    getNextPageParam: (lastPage, allPages) => allPages.length + 1,
    cacheTime: 0,
  });

  return {
    infiniteQuery: infiniteQuery,
    count: infiniteQuery.data?.pages[0].count ?? 0,
    rows: infiniteQuery.data?.pages.flatMap((page) => page.rows) ?? [],
    loading:
      infiniteQuery.isLoading ||
      infiniteQuery.isFetching ||
      infiniteQuery.isRefetching,
  };
}
