export enum EXCEL_COLUMN_TYPE {
  STRING = 'STRING',
  NUMBER = 'NUBMER',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  CURRENCY = 'CURRENCY',
  BOOLEAN = 'BOOLEAN',
  GENDER = 'GENDER',
  FORM_INSTANCE_RESULT = 'FORM_INSTANCE_RESULT',
}
