import { DefaultResponseDto, PagingDataDto } from 'dto/common';
import {
  CreatePersonDto,
  FindAllPersonDto,
  PersonCompactDto,
  PersonDto,
  UpdatePersonDto,
} from 'dto/person';
import axiosClient from 'utils/axios-client';

export const personAPI = {
  create(createPersonDto: CreatePersonDto): Promise<PersonDto> {
    return axiosClient.post('person', createPersonDto);
  },
  findAll(
    findAllPersonDto: FindAllPersonDto
  ): Promise<PagingDataDto<PersonDto>> {
    return axiosClient.get('person', { params: findAllPersonDto });
  },
  findAllCompact(
    findAllPersonDto: FindAllPersonDto
  ): Promise<PagingDataDto<PersonCompactDto>> {
    return axiosClient.get('person/compact', { params: findAllPersonDto });
  },
  findOne(id: number): Promise<PersonDto> {
    return axiosClient.get(`person/${id}`);
  },
  update(id: number, updatePersonDto: UpdatePersonDto): Promise<PersonDto> {
    return axiosClient.put(`person/${id}`, updatePersonDto);
  },
  delete(id: number): Promise<DefaultResponseDto> {
    return axiosClient.delete(`person/${id}`);
  },
};
