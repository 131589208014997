import { Close } from '@mui/icons-material';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
} from '@mui/material';
import { useState } from 'react';

type PropsType = {
  anchor?: 'left' | 'top' | 'right' | 'bottom';
  open?: boolean;
  onClose?: () => void;
  title?: React.ReactNode | string;
  children?: React.ReactNode;
  footer?: React.ReactNode | React.ReactNode[];
  width?: number;
};

function CustomizedDrawer(props: PropsType) {
  const [open, setOpen] = useState(props.open ?? false);

  const handleClose = () => {
    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Drawer
      open={props.open ?? open}
      onClose={handleClose}
      aria-labelledby="CustomizedDrawer"
      anchor={props.anchor}
    >
      <Box
        sx={{
          width: props.width ?? 400,
          maxWidth: '90vw',
          height: '100vh',
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontSize: '1.25rem',
            minHeight: 64,
          }}
          id="CustomizedDrawer"
        >
          {props.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        {props.children && (
          <DialogContent
            dividers
            sx={{
              p: 2,
              height: '100%',
              overflow: 'auto',
              maxHeight: 'calc(100vh - 64px - 68.5px)',
            }}
          >
            {props.children}
          </DialogContent>
        )}
        {props.footer && (
          <DialogActions sx={{ p: 2 }}>{props.footer}</DialogActions>
        )}
      </Box>
    </Drawer>
  );
}

export default CustomizedDrawer;
