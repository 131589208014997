import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { CustomizedDialog } from 'components/common';
import { DefaultResponseDto } from 'dto/common';
import { UseMutationResult } from 'react-query';
import { locales } from 'utils/constants';

type PropsType = {
  selectedId: number;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onMutation: () => UseMutationResult<
    DefaultResponseDto,
    unknown,
    number,
    unknown
  >;
};

function DeleteDialog(props: PropsType) {
  const { mutate, isLoading } = props.onMutation();

  function onSubmit(): void {
    mutate(props.selectedId, {
      onSuccess: () => {
        props.onSuccess();
      },
    });
  }

  return (
    <CustomizedDialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      title={locales.delete}
      footer={[
        <LoadingButton
          key="cancel"
          color="error"
          variant="outlined"
          loading={isLoading}
          onClick={props.onClose}
        >
          {locales.cancel}
        </LoadingButton>,
        <LoadingButton
          key="submit"
          color="primary"
          variant="contained"
          loading={isLoading}
          onClick={onSubmit}
        >
          {locales.submit}
        </LoadingButton>,
      ]}
    >
      <Typography textAlign="center" variant="inherit" className="py-5">
        {locales.areYouSureWantToDelete}
      </Typography>
    </CustomizedDialog>
  );
}

export default DeleteDialog;
