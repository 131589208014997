import { MenuTwoTone } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Logo } from 'components/common';
import { ProfileMenu } from 'components/menu';
import { themeStyles } from 'themes/styles';

type PropsType = {
  handleDrawerOpen: () => void;
};

function Header(props: PropsType) {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <AppBar
      id="MainLayout-Header"
      position="fixed"
      sx={{ zIndex: 1200, background: themeStyles.backgoundLayout }}
    >
      <Toolbar>
        <Box width={matchUpMd ? themeStyles.mainLayoutDrawerWidth : 0}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Grid item>
                <Box mt={0.5}>
                  <Logo isShowText theme="dark" />
                </Box>
              </Grid>
            </Box>
            <Grid item>
              <IconButton
                edge="start"
                sx={{ mr: theme.spacing(1.25) }}
                color="inherit"
                aria-label="open drawer"
                onClick={props.handleDrawerOpen}
                size="large"
              >
                <MenuTwoTone sx={{ fontSize: '1.5rem' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <ProfileMenu />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
