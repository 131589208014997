import { Link } from 'react-router-dom';
import { PATH } from 'routes/constants';
import { Grid, Typography } from '@mui/material';
import { locales } from 'utils/constants';

function LoginLink() {
  return (
    <Grid id="LoginLink" container justifyContent="end">
      <Grid item>
        <Link to={PATH.LOGIN} style={{ textDecoration: 'none' }}>
          <Typography variant="subtitle2" color="primary">
            {locales.login}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
}

export default LoginLink;
