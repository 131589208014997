export enum QUERY_KEY {
  GET_MY_PROFILE = 'GET_MY_PROFILE',
  FIND_ALL_ADMIN_USER = 'FIND_ALL_ADMIN_USER',
  FIND_ONE_ADMIN_USER = 'FIND_ONE_ADMIN_USER',
  FIND_ALL_ADMIN_ROLE = 'FIND_ALL_ADMIN_ROLE',
  FIND_ALL_ADMIN_ROLE_COMPACT = 'FIND_ALL_ADMIN_ROLE_COMPACT',
  FIND_ONE_ADMIN_ROLE = 'FIND_ONE_ADMIN_ROLE',
  FIND_ALL_USER = 'FIND_ALL_USER',
  FIND_ONE_USER = 'FIND_ONE_USER',
  FIND_ALL_PROGRAM = 'FIND_ALL_PROGRAM',
  FIND_ALL_PROGRAM_COMPACT = 'FIND_ALL_PROGRAM_COMPACT',
  FIND_ONE_PROGRAM = 'FIND_ONE_PROGRAM',
  FIND_ALL_PERSON = 'FIND_ALL_PERSON',
  FIND_ALL_PERSON_COMPACT = 'FIND_ALL_PERSON_COMPACT',
  FIND_ONE_PERSON = 'FIND_ONE_PERSON',
  FIND_ALL_PERIOD = 'FIND_ALL_PERIOD',
  FIND_ALL_PERIOD_COMPACT = 'FIND_ALL_PERIOD_COMPACT',
  FIND_ONE_PERIOD = 'FIND_ONE_PERIOD',
  FIND_ALL_ORGUNIT = 'FIND_ALL_ORGUNIT',
  FIND_ALL_ORGUNIT_COMPACT = 'FIND_ALL_ORGUNIT_COMPACT',
  FIND_ONE_ORGUNIT = 'FIND_ONE_ORGUNIT',
  FIND_ALL_DATAELEMENT = 'FIND_ALL_DATAELEMENT',
  FIND_ALL_DATAELEMENT_COMPACT = 'FIND_ALL_DATAELEMENT_COMPACT',
  FIND_ONE_DATAELEMENT = 'FIND_ONE_DATAELEMENT',
  FIND_ALL_DATASET = 'FIND_ALL_DATASET',
  FIND_ALL_DATASET_COMPACT = 'FIND_ALL_DATASET_COMPACT',
  FIND_ONE_DATASET = 'FIND_ONE_DATASET',
  FIND_ALL_FORM = 'FIND_ALL_FORM',
  FIND_ALL_FORM_COMPACT = 'FIND_ALL_FORM_COMPACT',
  FIND_ONE_FORM = 'FIND_ONE_FORM',
  FIND_ALL_FORM_INSTANCE = 'FIND_ALL_FORM_INSTANCE',
  FIND_ONE_FORM_INSTANCE = 'FIND_ONE_FORM_INSTANCE',
  COUNT_FORM_INSTANCE = 'COUNT_FORM_INSTANCE',
  COUNT_FORM = 'COUNT_FORM',
  COUNT_USER = 'COUNT_USER',
  COUNT_PERSON = 'COUNT_PERSON',
  DASHBOARD_SUMMARY = 'DASHBOARD_SUMMARY',
  STATISTIC_USER_BY_FORM_INSTANCE = 'STATISTIC_USER_BY_FORM_INSTANCE',
  STATISTIC_PROVINCE_BY_FORM_INSTANCE = 'STATISTIC_PROVINCE_BY_FORM_INSTANCE',
  GET_INITIAL_VALUES_FORM_INSTANCE = 'GET_INITIAL_VALUES_FORM_INSTANCE',
}
