import { Box, Divider, Drawer, Grid, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Logo } from 'components/common';
import { MainMenu } from 'components/menu';
import { themeStyles } from 'themes/styles';

type PropsType = {
  drawerOpen: boolean;
  handleDrawerOpen: () => void;
};

function Sidebar({ drawerOpen, handleDrawerOpen }: PropsType) {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const drawer = (
    <>
      <Box sx={{ display: { md: 'none', xs: 'block' } }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          sx={{
            ...theme.mixins.toolbar,
            lineHeight: 0,
            background: themeStyles.backgoundLayout,
          }}
        >
          <Grid item>
            <Logo isShowText theme="dark" />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <div
        style={{
          height: 'calc(100vh - 65px)',
          maxHeight: 'calc(100vh - 65px)',
          overflow: 'auto',
          padding: '10px',
        }}
      >
        <MainMenu />
      </div>
    </>
  );

  return (
    <Nav id="MainLayout-Sidebar">
      <Drawer
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerOpen}
        sx={{
          '& .MuiDrawer-paper': {
            width: themeStyles.mainLayoutDrawerWidth,
            borderRight: 'none',
            boxShadow: '0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15)',
            top: { md: 64, sm: 0 },
          },
        }}
        ModalProps={{ keepMounted: true }}
      >
        {drawer}
      </Drawer>
    </Nav>
  );
}

const Nav = styled(
  (
    props: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLElement>,
      HTMLElement
    >
  ) => <nav {...props} />
)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: themeStyles.mainLayoutDrawerWidth,
    flexShrink: 0,
  },
}));

export default Sidebar;
