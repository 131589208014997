import { Link } from 'react-router-dom';
import { PATH } from 'routes/constants';
import { Grid, Typography } from '@mui/material';
import { locales } from 'utils/constants';

function ForgotPasswordLink() {
  return (
    <Grid id="ForgotPasswordLink" container justifyContent="flex-end">
      <Grid item>
        <Link to={PATH.FORGOT_PASSWORD} style={{ textDecoration: 'none' }}>
          <Typography variant="subtitle2" color="primary">
            {locales.forgotPassword}?
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
}

export default ForgotPasswordLink;
