import AuthLayout from 'layouts/AuthLayout';
import MainLayout from 'layouts/MainLayout';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PATH } from './constants';

const NotFoundPage = lazy(() => import('pages/404'));
const HomePage = lazy(() => import('pages/Home'));
const LoginPage = lazy(() => import('pages/Login'));
const ForgotPasswordPage = lazy(() => import('pages/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('pages/ResetPassword'));
// const ProfilePage = lazy(() => import('pages/Profile'));
const AdminRolesPage = lazy(() => import('pages/AdminRoles'));
const AdminUsersPage = lazy(() => import('pages/AdminUsers'));
const UsersPage = lazy(() => import('pages/Users'));
const ProgramPage = lazy(() => import('pages/Program'));
const PeriodPage = lazy(() => import('pages/Period'));
const OrgunitPage = lazy(() => import('pages/Orgunit'));
const PersonPage = lazy(() => import('pages/Person'));
const DataelementPage = lazy(() => import('pages/Dataelement'));
const DatasetPage = lazy(() => import('pages/Dataset'));
const FormPage = lazy(() => import('pages/Form'));
const FormInstancePage = lazy(() => import('pages/FormInstance'));

export const routes: RouteObject[] = [
  {
    Component: MainLayout,
    children: [
      {
        path: PATH.ALL,
        Component: NotFoundPage,
      },
      {
        path: PATH.HOME,
        Component: HomePage,
      },
      // {
      //   path: PATH.PROFILE,
      //   Component: ProfilePage,
      // },
      {
        path: PATH.ADMIN_ROLES,
        Component: AdminRolesPage,
      },
      {
        path: PATH.ADMIN_USERS,
        Component: AdminUsersPage,
      },
      {
        path: PATH.USERS,
        Component: UsersPage,
      },
      {
        path: PATH.PROGRAM,
        Component: ProgramPage,
      },
      {
        path: PATH.PERIOD,
        Component: PeriodPage,
      },
      {
        path: PATH.ORGUNIT,
        Component: OrgunitPage,
      },
      {
        path: PATH.PERSON,
        Component: PersonPage,
      },
      {
        path: PATH.DATAELEMENT,
        Component: DataelementPage,
      },
      {
        path: PATH.DATASET,
        Component: DatasetPage,
      },
      {
        path: PATH.FORM,
        Component: FormPage,
      },
      {
        path: PATH.FORM_INSTANCE,
        Component: FormInstancePage,
      },
    ],
  },
  {
    Component: AuthLayout,
    children: [
      {
        path: PATH.LOGIN,
        Component: LoginPage,
      },
      {
        path: PATH.FORGOT_PASSWORD,
        Component: ForgotPasswordPage,
      },
      {
        path: PATH.RESET_PASSWORD,
        Component: ResetPasswordPage,
      },
    ],
  },
];
