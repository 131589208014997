import { periodAPI } from 'api/period';
import { UpdatePeriodDto } from 'dto/period';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { locales } from 'utils/constants';
import { QUERY_KEY } from 'utils/enums';

type PropsType = {
  id: number;
};

export function useUpdatePeriod(props: PropsType) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (payload: UpdatePeriodDto) =>
      periodAPI.update(props.id, payload),
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEY.FIND_ONE_PERIOD, props.id], data);
      toast(locales.updateSuccess, { type: 'success' });
    },
  });

  return mutation;
}
