import {
  AccountBox,
  AccountTree,
  Assignment,
  Dataset,
  DatasetLinked,
  Description,
  DeveloperBoard,
  ExpandLess,
  ExpandMore,
  Group,
  Home,
  ManageAccounts,
  PendingActions,
  Person,
  Settings,
} from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Loading } from 'components/common';
import { useGetMyProfile } from 'hooks/admin-users';
import { memo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PATH } from 'routes/constants';
import { locales } from 'utils/constants';
import { PERMISSION } from 'utils/enums';

function MainMenu() {
  const location = useLocation();
  const { data, isLoading } = useGetMyProfile();
  const [current, setCurrent] = useState('');
  const [openGroup, setOpenGroup] = useState('');

  function renderItem(item: ItemType, level: number = 1) {
    return (
      <Link
        key={item.key}
        to={item.pathname ?? ''}
        style={{ textDecoration: 'none' }}
      >
        <ListItemButton
          sx={{
            borderRadius: '5px',
            marginBottom: '5px',
            pl: `${level * 16}px`,
          }}
          selected={current === item.key}
        >
          <ListItemIcon sx={{ minWidth: 25 }}>{item.icon}</ListItemIcon>
          <ListItemText
            primary={
              <Typography
                sx={{
                  pl: 1.4,
                  fontWeight: current === item.key ? 'bold' : '500',
                }}
                variant={current === item.key ? 'subtitle1' : 'body1'}
                color="inherit"
              >
                {item.title}
              </Typography>
            }
          />
        </ListItemButton>
      </Link>
    );
  }

  function renderGroup(group: ItemType) {
    const selectedItem = !!group.children?.find(
      (childrenItem) => childrenItem.key === current
    );

    return (
      <div key={group.key}>
        <ListItemButton
          sx={{
            borderRadius: '5px',
            marginBottom: '5px',
            pl: `16px`,
            backgroundColor: 'transparent !important',
          }}
          selected={selectedItem}
          onClick={() =>
            setOpenGroup((openGroup) => (openGroup ? '' : group.key))
          }
        >
          <ListItemIcon sx={{ minWidth: 25 }}>{group.icon}</ListItemIcon>
          <ListItemText
            primary={
              <Typography
                sx={{
                  pl: 1.4,
                  fontWeight: selectedItem ? 'bold' : '500',
                }}
                variant={selectedItem ? 'subtitle1' : 'body1'}
                color="inherit"
              >
                {group.title}
              </Typography>
            }
          />
          {openGroup === group.key ? (
            <ExpandLess sx={{ fontSize: '1rem' }} />
          ) : (
            <ExpandMore sx={{ fontSize: '1rem' }} />
          )}
        </ListItemButton>
        <Collapse in={openGroup === group.key} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {group.children?.map((item) => renderItem(item, 2))}
          </List>
        </Collapse>
      </div>
    );
  }

  useEffect(() => {
    if (location.pathname === PATH.HOME) {
      setCurrent('home');
    } else {
      const flatMapItems: ItemType[] = [];
      items.forEach((item) => {
        flatMapItems.push(item);

        if (item.children) {
          flatMapItems.push(
            ...item.children.map((childrenItem) => ({
              ...childrenItem,
              parentKey: item.key,
            }))
          );
        }
      });

      const selectedItem = flatMapItems
        .slice(1)
        .find(
          (item) =>
            item.pathname &&
            location.pathname.indexOf(item.pathname ?? '') === 0
        );

      setCurrent(selectedItem?.key ?? '');
      if (selectedItem?.parentKey) {
        setOpenGroup(selectedItem?.parentKey ?? '');
      }
    }
  }, [location.pathname]);

  if (isLoading) {
    return <Loading height={300} />;
  }

  if (!data) {
    return null;
  }

  const menuItems = items
    .filter(
      (item) =>
        !item.permission ||
        data?.adminRole?.permissions?.includes(item.permission)
    )
    .map((item) =>
      item.children
        ? {
            ...item,
            children: item.children.filter(
              (childrenItem) =>
                !childrenItem.permission ||
                data?.adminRole?.permissions?.includes(childrenItem.permission)
            ),
          }
        : item
    );

  return (
    <List id="MainMenu">
      {menuItems.map((item) =>
        item.children ? renderGroup(item) : renderItem(item)
      )}
    </List>
  );
}

type ItemType = {
  key: string;
  title: string;
  icon: JSX.Element;
  pathname?: PATH;
  permission?: PERMISSION;
  children?: ItemType[];
  parentKey?: string;
};

const items: ItemType[] = [
  {
    key: 'home',
    title: locales.dashboard,
    icon: <Home />,
    pathname: PATH.HOME,
  },
  {
    key: 'form-instance',
    title: locales.formInstance,
    icon: <Assignment />,
    pathname: PATH.FORM_INSTANCE,
    permission: PERMISSION.READ_FORM_INSTANCE,
  },
  {
    key: 'config-form',
    title: locales.configForm,
    icon: <Settings />,
    permission: PERMISSION.READ_FORM,
    children: [
      {
        key: 'form',
        title: locales.form,
        icon: <Description />,
        pathname: PATH.FORM,
        permission: PERMISSION.READ_FORM,
      },
      {
        key: 'dataset',
        title: locales.dataset,
        icon: <DatasetLinked />,
        pathname: PATH.DATASET,
        permission: PERMISSION.READ_DATASET,
      },
      {
        key: 'dataelement',
        title: locales.dataelement,
        icon: <Dataset />,
        pathname: PATH.DATAELEMENT,
        permission: PERMISSION.READ_DATAELEMENT,
      },
      {
        key: 'person',
        title: locales.person,
        icon: <AccountBox />,
        pathname: PATH.PERSON,
        permission: PERMISSION.READ_PERSON,
      },
      {
        key: 'program',
        title: locales.program,
        icon: <DeveloperBoard />,
        pathname: PATH.PROGRAM,
        permission: PERMISSION.READ_PROGRAM,
      },
    ],
  },
  {
    key: 'orgunit',
    title: locales.orgunit,
    icon: <AccountTree />,
    pathname: PATH.ORGUNIT,
    permission: PERMISSION.READ_ORGUNIT,
  },
  {
    key: 'period',
    title: locales.period,
    icon: <PendingActions />,
    pathname: PATH.PERIOD,
    permission: PERMISSION.READ_PERIOD,
  },

  {
    key: 'user',
    title: locales.user,
    icon: <Person />,
    pathname: PATH.USERS,
    permission: PERMISSION.READ_USER,
  },
  {
    key: 'admin-user',
    title: locales.adminUser,
    icon: <ManageAccounts />,
    pathname: PATH.ADMIN_USERS,
    permission: PERMISSION.READ_ADMIN_USER,
  },
  {
    key: 'admin-role',
    title: locales.adminRole,
    icon: <Group />,
    pathname: PATH.ADMIN_ROLES,
    permission: PERMISSION.READ_ADMIN_ROLE,
  },
];

export default memo(MainMenu);
