export function capitalizeFirstLetter(text: string = '') {
  if (!text) {
    return text;
  }

  text = text.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function disableKeyDownInput(
  e: React.KeyboardEvent<HTMLDivElement>
): void {
  e.stopPropagation();
}

export function formatNumber(number: number, locale?: string): string {
  return new Intl.NumberFormat(locale ?? 'en-US').format(number);
}

export function removeSpecialCharacters(keyword: string): string {
  if (!keyword) {
    return '';
  }

  return keyword
    .toLowerCase()
    .replace('đ', 'd')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9]/g, ' ')
    .replace(/\\s+/g, ' ')
    .trim();
}
