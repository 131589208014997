import { Box } from '@mui/material';
import { Loadable, Loading } from 'components/common';
import { useGetMyProfile } from 'hooks/admin-users';
import ErrorBoundary from 'pages/500';
import { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { routes } from 'routes';
import { localStorageKey } from 'utils/constants';

function App() {
  const router = createBrowserRouter(routes);
  const profile = useGetMyProfile();

  useEffect(() => {
    if (localStorage.getItem(localStorageKey.accessToken)) {
      profile.refetch();
    }

    const listener = (e: StorageEvent) => {
      if (e.key === localStorageKey.accessToken && e.newValue) {
        profile.refetch();
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, []);

  return (
    <ErrorBoundary>
      <Loadable>
        {profile.isLoading ? (
          <Box
            display="flex"
            alignItems="center"
            sx={{ width: '100vw', height: '100vh' }}
          >
            <Loading text="Đang tải thông tin người dùng..." />
          </Box>
        ) : (
          <RouterProvider router={router} />
        )}
      </Loadable>
      <ToastContainer hideProgressBar autoClose={3000} />
    </ErrorBoundary>
  );
}

export default App;
