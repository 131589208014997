export const locales = {
  active: 'Hoạt động',
  add: 'Thêm',
  addNew: 'Thêm mới',
  address: 'Địa chỉ',
  adminRole: 'Vai trò quản trị viên',
  adminUser: 'Quản trị viên',
  andInputYourOtp: 'và nhập mã xác minh gồm 6 chữ số đã được gửi',
  areYouSureWantToDelete: 'Bạn chắc chắn muốn xoá?',
  belongsToUnit: 'Thuộc đơn vị',
  birthday: 'Ngày sinh',
  cancel: 'Huỷ bỏ',
  changeEmail: 'Thay đổi email',
  checkYourEmail: 'Kiểm tra email của bạn',
  code: 'Mã',
  confirm: 'Xác nhân',
  confirmPassword: 'Xác nhận mật khẩu',
  confirmPasswordNotMatch: 'Xác nhận mật khẩu không khớp',
  content: 'Nội dung',
  controlType: 'Kiểu đầu vào',
  currentPassword: 'Mật khẩu hiện tại',
  create: 'Tạo mới',
  createSuccess: 'Tạo mới thành công!',
  createdAt: 'Ngày tạo',
  createdBy: 'Người tạo',
  configForm: 'Cấu hình phiếu',
  dataelement: 'Dataelement',
  dataset: 'Dataset',
  dashboard: 'Dashboard',
  delete: 'Xoá',
  deleteSuccess: 'Xoá thành công!',
  description: 'Mô tả',
  email: 'Email',
  emptyData: 'Không có dữ liệu',
  explain: 'Diễn giải',
  female: 'Nữ',
  forgotPassword: 'Quên mật khẩu',
  form: 'Mẫu phiếu',
  formInstance: 'Phiếu sàng lọc',
  formInstanceIsNotPass: 'Kết quả bất thường',
  formInstanceIsPass: 'Kết quả bình thường',
  from: 'Từ',
  fromDate: 'Từ ngày',
  fullName: 'Họ tên',
  gender: 'Giới tính',
  home: 'Trang chủ',
  idnumber: 'CMND/CCCD',
  inactive: 'Không hoạt động',
  info: 'Thông tin',
  login: 'Đăng nhập',
  loginSuccess: 'Đăng nhập thành công!',
  logout: 'Đăng xuất',
  male: 'Nam',
  maxscore: 'Điểm tối đa',
  minscore: 'Điểm tối thiểu',
  monthsOld: 'Tháng tuổi',
  name: 'Tên',
  no: 'STT',
  notPass: 'Nghi ngờ KT',
  orgunit: 'Đơn vị hành chính',
  other: 'Khác',
  pageNotFound: 'Xin lỗi, trang bạn truy cập không tồn tại.',
  parentname: 'Tên cha mẹ',
  pass: 'Bình thường',
  password: 'Mật khẩu',
  period: 'Đợt sàng lọc',
  permission: 'Quyền',
  person: 'Đối tượng',
  phoneNumber: 'Số điện thoại',
  pleaseInputYourEmail:
    'Vui lòng nhập địa chỉ email được sử dụng để đăng ký tài khoản của bạn.',
  profile: 'Hồ sơ',
  program: 'Chương trình',
  read: 'Xem',
  resend: 'Gửi lại',
  resetPassword: 'Đặt lại mật khẩu',
  result: 'Kết quả',
  retry: 'Thử lại',
  role: 'Vai trò',
  score: 'Điểm',
  search: 'Tìm kiếm',
  sendEmailSuccess: 'Gửi email thành công!',
  somethingWentWrong: 'Xin lỗi, có lỗi xảy ra.',
  status: 'Trạng thái',
  submit: 'Xác nhận',
  surveyby: 'Người khảo sát',
  surveyplace: 'Nơi khảo sát',
  to: 'Đến',
  toDate: 'Đến ngày',
  tryAgainAfterSeconds: (seconds: number) => `Thử lại sau ${seconds} giây`,
  type: 'Loại',
  update: 'Cập nhật',
  updatePassword: 'Đổi mật khẩu',
  updatePasswordSuccess: 'Đổi mật khẩu thành công!',
  updateSuccess: 'Cập nhật thành công!',
  updatedAt: 'Ngày cập nhật',
  updatedBy: 'Người cập nhật',
  user: 'Cán bộ sàng lọc',
  value: 'Giá trị',
  verifySuccess: 'Xác minh thành công!',
  youDidntReceiveOtp: 'Bạn không nhận được mã xác minh?',
  exportExcel: 'Xuất excel',
  exportExcelSuccess: 'Xuất excel thành công!',
  formInstanceUnit: 'Phiếu',
  province: 'Tỉnh',
  district: 'Huyện',
  ward: 'Xã',
  time: 'Thời gian',
  thisMonth: 'Tháng này',
  thisYear: 'Năm nay',
  lastYear: 'Năm trước',
  optional: 'Tuỳ chọn',
  filters: 'Bộ lọc',
  formInstanceResult: 'Kết quả sàng lọc',
  evaluate: 'Đánh giá',
  noSuitableFormWasFound: 'Không tìm thấy mẫu phiếu phù hợp',
  config: 'Cấu hình',
};
