import { DefaultResponseDto, PagingDataDto } from 'dto/common';
import {
  CreatePeriodDto,
  FindAllPeriodDto,
  PeriodCompactDto,
  PeriodDto,
  UpdatePeriodDto,
} from 'dto/period';
import axiosClient from 'utils/axios-client';

export const periodAPI = {
  create(createPeriodDto: CreatePeriodDto): Promise<PeriodDto> {
    return axiosClient.post('period', createPeriodDto);
  },
  findAll(
    findAllPeriodDto: FindAllPeriodDto
  ): Promise<PagingDataDto<PeriodDto>> {
    return axiosClient.get('period', { params: findAllPeriodDto });
  },
  findAllCompact(
    findAllPeriodDto: FindAllPeriodDto
  ): Promise<PagingDataDto<PeriodCompactDto>> {
    return axiosClient.get('period/compact', { params: findAllPeriodDto });
  },
  findOne(id: number): Promise<PeriodDto> {
    return axiosClient.get(`period/${id}`);
  },
  update(id: number, updatePeriodDto: UpdatePeriodDto): Promise<PeriodDto> {
    return axiosClient.put(`period/${id}`, updatePeriodDto);
  },
  delete(id: number): Promise<DefaultResponseDto> {
    return axiosClient.delete(`period/${id}`);
  },
};
