import { DefaultResponseDto, PagingDataDto } from 'dto/common';
import {
  CreateDataelementDto,
  DataelementCompactDto,
  DataelementDto,
  FindAllDataelementDto,
  UpdateDataelementDto,
} from 'dto/dataelement';
import axiosClient from 'utils/axios-client';

export const dataelementAPI = {
  create(createDataelementDto: CreateDataelementDto): Promise<DataelementDto> {
    return axiosClient.post('dataelement', createDataelementDto);
  },
  findAll(
    findAllDataelementDto: FindAllDataelementDto
  ): Promise<PagingDataDto<DataelementDto>> {
    return axiosClient.get('dataelement', { params: findAllDataelementDto });
  },
  findAllCompact(
    findAllDataelementDto: FindAllDataelementDto
  ): Promise<PagingDataDto<DataelementCompactDto>> {
    return axiosClient.get('dataelement/compact', {
      params: findAllDataelementDto,
    });
  },
  findOne(id: number): Promise<DataelementDto> {
    return axiosClient.get(`dataelement/${id}`);
  },
  update(
    id: number,
    updateDataelementDto: UpdateDataelementDto
  ): Promise<DataelementDto> {
    return axiosClient.put(`dataelement/${id}`, updateDataelementDto);
  },
  delete(id: number): Promise<DefaultResponseDto> {
    return axiosClient.delete(`dataelement/${id}`);
  },
};
