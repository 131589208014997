import { DefaultResponseDto, PagingDataDto } from 'dto/common';
import {
  CreateDatasetDto,
  DatasetCompactDto,
  DatasetDto,
  FindAllDatasetDto,
  UpdateDatasetDto,
} from 'dto/dataset';
import axiosClient from 'utils/axios-client';

export const datasetAPI = {
  create(createDatasetDto: CreateDatasetDto): Promise<DatasetDto> {
    return axiosClient.post('dataset', createDatasetDto);
  },
  findAll(
    findAllDatasetDto: FindAllDatasetDto
  ): Promise<PagingDataDto<DatasetDto>> {
    return axiosClient.get('dataset', { params: findAllDatasetDto });
  },
  findAllCompact(
    findAllDatasetDto: FindAllDatasetDto
  ): Promise<PagingDataDto<DatasetCompactDto>> {
    return axiosClient.get('dataset/compact', { params: findAllDatasetDto });
  },
  findOne(id: number): Promise<DatasetDto> {
    return axiosClient.get(`dataset/${id}`);
  },
  update(id: number, updateDatasetDto: UpdateDatasetDto): Promise<DatasetDto> {
    return axiosClient.put(`dataset/${id}`, updateDatasetDto);
  },
  delete(id: number): Promise<DefaultResponseDto> {
    return axiosClient.delete(`dataset/${id}`);
  },
};
