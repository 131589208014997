import { Stack, Typography } from '@mui/material';

type PropsType = {
  items: {
    label?: React.ReactNode;
    value?: React.ReactNode;
  }[];
  style?: React.CSSProperties;
};

function Descriptions(props: PropsType) {
  return (
    <Stack gap={1} style={props.style}>
      {props.items.map((item, index) => (
        <div
          key={`description-item-${index}`}
          className="flex justify-between gap-x-4"
        >
          {item.label && (
            <Typography
              component="div"
              className="font-[550] min-w-[30%]"
              variant="subtitle1"
            >
              {item.label}:
            </Typography>
          )}
          {item.value && (
            <Typography
              component="div"
              className="'text-end'"
              sx={{ width: !item.label ? '100%' : 'fit-content' }}
            >
              {item.value}
            </Typography>
          )}
        </div>
      ))}
    </Stack>
  );
}

export default Descriptions;
