import { Box, Stack, Typography } from '@mui/material';
import { themeStyles } from 'themes/styles';

function LogoBoYTe() {
  return (
    <Box
      id="LogoBoYTe"
      sx={{ maxWidth: 450, color: themeStyles.textLight, margin: 'auto' }}
    >
      <Stack direction="row" spacing={1}>
        <img
          alt="LogoBoYTe"
          src="/logo-boyte.png"
          width={100}
          height={100}
          style={{ width: 100, height: 100, objectFit: 'cover' }}
        />
        <Typography>
          BỘ Y TẾ
          <br />
          CỤC QUẢN LÝ KHÁM CHỮA BỆNH
          <br />
          Department of medical service administration - Ministry of health,
          Viet Nam
        </Typography>
      </Stack>
    </Box>
  );
}

export default LogoBoYTe;
