import { personAPI } from 'api/person';
import { FindAllPersonDto } from 'dto/person';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { QUERY_KEY } from 'utils/enums';

type PropsType = {
  filters: FindAllPersonDto;
};

export function useFindAllPerson(props: PropsType) {
  const query = useQuery({
    queryKey: QUERY_KEY.FIND_ALL_PERSON,
    queryFn: () => personAPI.findAll(props.filters),
  });

  useEffect(() => {
    query.refetch();
  }, [props.filters]);

  return {
    query: query,
    count: query.data?.count ?? 0,
    rows: query.data?.rows ?? [],
    loading: query.isLoading || query.isFetching || query.isRefetching,
  };
}
