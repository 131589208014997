import { personAPI } from 'api/person';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { locales } from 'utils/constants';
import { QUERY_KEY } from 'utils/enums';

export function useCreatePerson() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: personAPI.create,
    onSuccess: (data) => {
      queryClient.setQueryData(QUERY_KEY.FIND_ONE_PERSON, data);
      toast(locales.createSuccess, { type: 'success' });
    },
  });

  return mutation;
}
