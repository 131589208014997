import { orgunitAPI } from 'api/orgunit';
import { FindAllOrgunitDto } from 'dto/orgunit';
import { useInfiniteQuery } from 'react-query';
import { defaultCompactPageSize, defaultPageIndex } from 'utils/constants';
import { QUERY_KEY } from 'utils/enums';

type PropsType = {
  filters: FindAllOrgunitDto;
};

export function useFindAllOrgunitCompact(props: PropsType) {
  const infiniteQuery = useInfiniteQuery({
    queryKey: [QUERY_KEY.FIND_ALL_ORGUNIT_COMPACT, props.filters],
    queryFn: ({ pageParam = defaultPageIndex }) =>
      orgunitAPI.findAllCompact({
        ...props.filters,
        pageIndex: pageParam,
        pageSize: defaultCompactPageSize,
      }),
    getNextPageParam: (lastPage, allPages) => allPages.length + 1,
    cacheTime: 0,
  });

  return {
    infiniteQuery: infiniteQuery,
    count: infiniteQuery.data?.pages[0].count ?? 0,
    rows: infiniteQuery.data?.pages.flatMap((page) => page.rows) ?? [],
    loading:
      infiniteQuery.isLoading ||
      infiniteQuery.isFetching ||
      infiniteQuery.isRefetching,
  };
}
