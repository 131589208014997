import { MeetingRoomTwoTone, PersonTwoTone } from '@mui/icons-material';
import {
  Avatar,
  Button,
  ClickAwayListener,
  Fade,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useTheme,
} from '@mui/material';
import { ProfileDrawer } from 'components/admin-users';
import { useGetMyProfile } from 'hooks/admin-users/useGetMyProfile';
import { useLogout } from 'hooks/auth/useLogout';
import { memo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PATH } from 'routes/constants';
import { locales } from 'utils/constants';

function ProfileMenu() {
  const theme = useTheme();
  const location = useLocation();

  const profile = useGetMyProfile();
  const logout = useLogout();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);

  return (
    <div id="ProfileMenu">
      <Button
        sx={{ gap: 1 }}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        aria-label="Profile"
        onClick={() => setOpen((open) => !open)}
        color="inherit"
      >
        <Avatar alt="avatar" sx={{ width: 24, height: 24 }} />
        <Typography sx={{ textTransform: 'none' }}>
          {profile.data?.fullName}
        </Typography>
      </Button>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 200,
                    minWidth: 150,
                    backgroundColor: theme.palette.background.paper,
                    pb: 0,
                    borderRadius: '10px',
                  }}
                >
                  <ListItemButton
                    selected={location.pathname === PATH.PROFILE}
                    onClick={() => {
                      setOpen(false);
                      setOpenView(true);
                    }}
                  >
                    <ListItemIcon>
                      <PersonTwoTone />
                    </ListItemIcon>
                    <ListItemText primary={locales.profile} />
                  </ListItemButton>
                  <ListItemButton onClick={() => logout.mutate()}>
                    <ListItemIcon>
                      <MeetingRoomTwoTone />
                    </ListItemIcon>
                    <ListItemText primary={locales.logout} />
                  </ListItemButton>
                </List>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <ProfileDrawer open={openView} onClose={() => setOpenView(false)} />
    </div>
  );
}

export default memo(ProfileMenu);
