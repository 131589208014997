export enum PATH {
  ALL = '*',
  HOME = '/',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  PROFILE = '/profile',
  USERS = '/users',
  ADMIN_ROLES = '/admin-roles',
  ADMIN_USERS = '/admin-users',
  DATAELEMENT = '/dataement',
  DATASET = '/dataset',
  FORM = '/form',
  FORM_INSTANCE = '/form-instance',
  ORGUNIT = '/orgunit',
  PERIOD = '/period',
  PERSON = '/person',
  PROGRAM = '/program',
}
